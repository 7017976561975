import React from 'react';
import ReactDOM from 'react-dom/client';
import { useState } from 'react';
import Loader from './loader.js';
import mainMenu from './mainMenu.js';
import './assets/css/bite-style.css';
import gear from './assets/settings.png';
import spinner from './assets/css/svg/s (1).svg';
import Toggle from './toggle.js';
import contactForm from './contactForm.js';
import Footer from './footer.js';
const isOsSettingDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

function Content() {
const [isDark, setIsDark] = useState(isOsSettingDarkTheme);
let mode='';
return (
<div className="BiteApp" data-theme={isDark ? "dark" : "light"}>
      <div className='appMenu'>
        <div className='appMenuItems'>
           <a href="#home">Home</a>
           <a href="#services"><div>Services</div></a>
           <a href="#contact"><div>Contact</div></a>
        </div>
        <div className="bottomAligned">
           <div className='toggle flcenter'>
           <Toggle isChecked={isDark} handleChange={() => setIsDark(!isDark)} />{isDark? mode="Light mode" :  mode="Dark mode"}
           </div>
        </div>
       </div>
      <div className="appScreen" id="home">
        <div className="actual">
         {mainMenu()} 
         
            {/*/////////////////////////////////////HERO///////////////////////////////////////////////////////////*/}
          <section><div className="hero">
                <div className="heroLeft">
                    <h1>Get More Results from Your Website.<span style={{color:'#8093f1'}}>.</span></h1>   
                    <p>More traffic, more engagements, more leads, more conversions.</p>
                    <div className="spacer30">
                      <a href="#contact"><button className="contactButton">I want results now &nbsp;&nbsp;
                        <svg height="10" width="70" xmlns="http://www.w3.org/2000/svg">
                            <path strokeWidth="2" fill="none" className="buttonArrow" d="M0,5 L60,5 L60,0 L70,5 L60,10 L60,5"/>
                        </svg></button>
                      </a>
                    </div> 
                </div>
                <div className="heroRight">
                      <svg height="400" width="400" xmlns="http://www.w3.org/2000/svg">
                           <polyline  className="chart" fill="none" stroke-miterlimit="10" strokeWidth="15"   points="10,400 100,250 250,300 360,50" />
                           <polygon points="340,45 376,60 385,0" className="polygon" opacity="0"> 
                                <animate attributeType="CSS" attributeName="opacity" from="0" to="1" begin="1.3s" dur="0.35s" fill="freeze"/>
                           </polygon>
                      </svg>
                </div>
            </div></section>
            
              {/*///////////////////////////////////////BEYOND DESIGN/////////////////////////////////////////////////////////*/}
            <section><div className="afterHero">
                <div className="columns reverse mc">
               <div className="columns50">
                </div>
               <div className="">
                <div className="spacer50"></div>
                <div className="spacer30"></div>   
                      <div style={{overflow:"hidden"}} className="columns slider fcenter grid100 row">
                          <img src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720591164/benfik-removebg-preview_kmwd9q.png" />
                          <img src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1718744619/Screenshot_2023-10-10_at_04.57.55-removebg-preview_qticfa.png" />
                          <img src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1705443462/vivaLuxe-logo.webp" />
                          <img src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1708076254/IMG_0186-removebg-preview.png" />
                          <img src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594535/finalbionic-01_vrydus.png" />
                          <img src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594374/Image-PNG-Transparent-Exact-Large_xyiqfd.png" />
                          <img src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592917/Lasht-n-2900-x-850-px-4_i19kcd.svg" />
                          <img src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592643/Raven-Gold_logo-removebg-preview-1-e1664598593566_mj6gvx.png" />
                          {/*repeat for loop*/}
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720591164/benfik-removebg-preview_kmwd9q.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1718744619/Screenshot_2023-10-10_at_04.57.55-removebg-preview_qticfa.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1705443462/vivaLuxe-logo.webp" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1708076254/IMG_0186-removebg-preview.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594535/finalbionic-01_vrydus.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594374/Image-PNG-Transparent-Exact-Large_xyiqfd.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592917/Lasht-n-2900-x-850-px-4_i19kcd.svg" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592643/Raven-Gold_logo-removebg-preview-1-e1664598593566_mj6gvx.png" />
                           {/*repeat for loop*/}
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720591164/benfik-removebg-preview_kmwd9q.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1718744619/Screenshot_2023-10-10_at_04.57.55-removebg-preview_qticfa.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1705443462/vivaLuxe-logo.webp" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1708076254/IMG_0186-removebg-preview.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594535/finalbionic-01_vrydus.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594374/Image-PNG-Transparent-Exact-Large_xyiqfd.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592917/Lasht-n-2900-x-850-px-4_i19kcd.svg" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592643/Raven-Gold_logo-removebg-preview-1-e1664598593566_mj6gvx.png" />
                          {/*repeat for loop*/}
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720591164/benfik-removebg-preview_kmwd9q.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1718744619/Screenshot_2023-10-10_at_04.57.55-removebg-preview_qticfa.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1705443462/vivaLuxe-logo.webp" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1708076254/IMG_0186-removebg-preview.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594535/finalbionic-01_vrydus.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594374/Image-PNG-Transparent-Exact-Large_xyiqfd.png" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592917/Lasht-n-2900-x-850-px-4_i19kcd.svg" />
                          <img className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592643/Raven-Gold_logo-removebg-preview-1-e1664598593566_mj6gvx.png" />
                      </div> 
               
               </div>
             </div>
          </div></section>
            <div className="spacer50"></div>

            
            {/*////////////////////////////////HARNESS TECH////////////////////////////////////////////////////////////////*/}
            <div className="parallax bg1"></div>
             <section><div className="pad border lightBulb">
                <div className="spacer50"></div>
                  <h1 className="boldHeader">Struggling with low traffic and conversions? Set up <img className="spin gear" src={gear}  /> your website to grow your business.</h1>
                   
                         <div className="columns top">
                             <div className="columns50 mHide">
                             <div className="spacer50"></div>
                                  <img src={spinner} className="flip" />
                              </div>
                            
                                <div className="columns50 mc">
                                <div className="spacer50"></div>
                           
                            <p>
                              Beyond design, we build websites that <i style={{backgroundColor:'#9fddfe', padding:'0 5px', color:'#1f1f1f', marginRight:'3px'}}>understand</i> 
                              the psychology of your customers and speak uniquely with them.
                              This helps you <i style={{backgroundColor:'', padding:'0 5px 0 0', color:'', marginRight:'3px'}}>connect</i> 
                              with your target audience, drive conversions and build relationships.
                            </p>
                            <div className="spacer20"></div>
                            <a href="#contact"><button className="contactButton">I want results now &nbsp;&nbsp;
                                  <svg height="10" width="70" xmlns="http://www.w3.org/2000/svg">
                                     <path strokeWidth="2" fill="none" className="buttonArrow" d="M0,5 L60,5 L60,0 L70,5 L60,10 L60,5"/>
                                  </svg></button>
                            </a>
                          <div className="spacer50"></div>
                        </div>
                   </div>
                </div>
            </section>
            
        {/*////////////////////////////////AFTER PARTNERING////////////////////////////////////////////////////////////////*/}
             <section><div className="spacer50 dHide"></div><div className="spacer20"></div>
                  <div className="">
                   <h3 className="bigHeading center">After partnering with us, our clients typically experience...</h3>
                   <div className="spacer30"></div>
                   <div className="columns mHide">
                       <div className="columns25 center">
                          <p> <span className="counter"><span className="purple counterIcon">+</span> 60% </span><br/>increase in traffic</p>
                       </div>
                       <div className="columns25 center">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 83% </span><br/>more engagement</p>
                       </div>
                       <div className="columns25 center">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 70% </span><br/>more leads</p>
                       </div>
                       <div className="columns25 center">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 50%</span><br/>more conversions</p>
                       </div></div>
                       
                       {/*Mobile counters*/}
                        <div className="mcolumns dHide">
                       <div className="columns25 center">
                          <p> <span className="counter"><span className="purple counterIcon">+</span> 60% </span><br/>increase in traffic</p>
                       </div>
                       <div className="columns25 center">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 83% </span><br/>more engagement</p>
                       </div></div>
                       <div className="mcolumns dHide">
                       <div className="columns25 center">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 79% </span><br/>more leads</p>
                       </div>
                       <div className="columns25 center">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 50%</span><br/>more conversions</p>
                       </div></div>
           
           <div id="services" className="spacer50"></div><div className="spacer20"></div></div>
           </section>
    
            {/*////////////////////////////////HOW WE GET RESULTS////////////////////////////////////////////////////////////////*/}
            <section><div className="spacer50 borderTop"></div>
                  <div className="">
                   <h3 className="bigHeading center">Here is how we will get you results</h3>
                   <div className="spacer30"></div>
                   <div className="columns grid">
                       <div className="columns3 center">
                           <div className="gridBox blueBg">
                             <h6 className="smHeading">Websites engineered for more conversions<br/> </h6>
                             <div className="spacer20"></div>
                               
                                <img className="resultsImg" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1724271259/Black_White_Vintage_Retro_Dynamic_Digital_Marketing_Presentation_fbl3hg.jpg" alt="website development and optimisation - BITe Agency.pro" />
                           </div>
                       </div>
                       <div className="columns3 center">
                           <div className="gridBox purpleBg">
                             <h6 className="smHeading">Content that connects with your customers</h6>
                             <div className="spacer20"></div>
                              <img className="resultsImg" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1724271258/Black_White_Vintage_Retro_Dynamic_Digital_Marketing_Presentation_1_puxyjg.jpg" alt="website content that drives conversions - BITe Agency.pro" />
                           </div>
                       </div>
                       <div className="columns3 center">
                           <div className="gridBox lavBg">
                             <h6 className="smHeading">Creative advertising to reach the right people</h6>
                             <div className="spacer20"></div>
                               <img className="resultsImg" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1724271259/Black_White_Vintage_Retro_Dynamic_Digital_Marketing_Presentation_3_mfvh07.jpg" alt="Reach the right audience with creative advertising - BITe Agency.pro" />
                           </div>
                       </div>
                </div><div className="spacer20"></div>
                    <div className="gridBox blueBg ' ' + columns">
                              <div className="columns20">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <style>
      {`
        .envelope { fill: #8093f1; }
        .flap { fill: #f4f4ed; }
        .background { fill: #9fddfe; }
      `}
    </style>
    <rect x="10" y="30" width="80" height="40" className="envelope" />
    <polygon points="10,30 50,60 90,30" className="flap">
      <animate id="crm" attributeName="points" values="10,30 50,60 90,30; 10,50 50,30 90,50; 10,30 50,60 90,30" dur="5s" begin="4s;crm.end+5s" />
    </polygon>
    <rect x="10" y="10" width="80" height="20" className="background" />
  </svg>
                              </div>
                              <div className="columns80 mcenter gridBox  mnopad">
                                 <h6 className="smHeading">Customer relationship management (CRM)</h6>
                                 <div className="spacer20"></div>
                                  <p className="footerP">We help you stay top of mind of your customers to keep them coming back.<br/>
                                  It's only half the results if you don't build a relationship that brings them back.
                                  </p>
                             </div>                               
                    </div>
                    
                       <div className="spacer20"></div>
                       <div className="center">
                       <a href="#contact"><button className="contactButton">I want results now &nbsp;&nbsp;
                         <svg height="10" width="70" xmlns="http://www.w3.org/2000/svg">
                             <path strokeWidth="2" fill="none" className="buttonArrow" d="M0,5 L60,5 L60,0 L70,5 L60,10 L60,5"/>
                         </svg></button>
                    </a>
                <div className="spacer50"></div>
              </div>
        </div>
            </section><div className="spacer0"></div>
         {/*////////////////////////////////BRANDS////////////////////////////////////////////////////////////////*/}
            {/*<section ><div className="spacer50"></div>
                  <div className="borderTop center"><div className="spacer50 mSpacer"></div>
                      <h3 className="bigHeading">We've done it for brands like yours, let's do it for you too.</h3>
                      
                      <div  className="spacer50"></div>
                 </div>  
            </section><div className="spacer50"></div>*/}
     {/*////////////////////////////////CONTACT////////////////////////////////////////////////////////////////*/}
         {/*<div className="parallax bg2"></div>*/}
            <section id="contact"><div className="spacer50 borderTop"></div>
                  <div className="">
                      
                      <div className="spacer30"></div>   
                      <div className="columns grid fcenter reverse top ">
                          <div className="columns70">
                          <div className="mspacer"></div>
                          <div className=""></div>
                              {contactForm()}
                          </div>
                          <div className="columns30 sticky">
                              <h3 className="bigHeading">Set up your website for big results.</h3>
                              <div className="spacer20"></div>
                              <p>
                                   Whatever you are looking to achieve, whether it is to get more traffic, reduce bounce rates
                                   or get more people to schedule a consultation with you, we
                                    <i style={{backgroundColor:'#9fddfe', padding:'0 5px', color:'#1f1f1f', marginRight:'3px'}}>guarantee</i> 
                                   we can improve your present outcomes by at least 30% within a period of 60 days. 
                                   If we don’t, we’ll work for you till we do.
                              </p>
                              
                          </div>
                      </div> 
                      <div className="spacer50"></div>
                 </div>  
            </section>
            {/*/////////////////FOOTER///////////////////*/}
            {Footer()}
            
           {/*close actual app screen*/}
    </div>
   </div>
</div>
);
}
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {favoritecontent: <Loader/>};
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({favoritecontent: <Content/>})
    }, 3000)
  }
  
  render() {
    return (
      <>{this.state.favoritecontent}</>
    );
  }
}

const preloader = ReactDOM.createRoot(document.getElementById('Bite'));
preloader.render(<App/>);