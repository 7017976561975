import React from 'react';
import './assets/css/bite-style.css';
import { MdLanguage, MdOutlineImportantDevices, MdOutlinePhonelink, MdOutlineQueryStats, MdOutlineCall, MdOutlineForum, MdOutlineMarkAsUnread, MdOutlineRadar, MdOutlinePhone, MdFacebook, MdInfoOutline } from "react-icons/md";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

function Footer() {
  const footer = (
  <>
    <section class="">
    <div>
    <div className="spacer50 borderTop"></div> 
    <div className="columns footerGrid">
        <div className="columns3">
           <h5 className="footerH">BITe Agency</h5>
           <div className="spacer30"></div> 
           <p className="footerP">
               BITe agency harnesses digital technologies to give you real results that grow your business.
               Our consumer psychology based methods ensure you aren't just spending money on digital technology,
               but earning good returns on your investments. With BITe, you can get a larger chunk of your market
               – no matter what level you are at.
           </p>
           <div className="spacer20"></div>
           <div className="columns grid social mcolumns">
              <a href="https://web.facebook.com/biteagency.pro"><FaFacebook/></a> 
              <a href="https://www.instagram.com/biteagency.pro/"><FaInstagram/></a> 
              <a href="https://www.linkedin.com/company/biteagencypro"><FaLinkedin/></a>
           </div>
        </div>
        <div className="columns3 footerGridBox">
          <a href="">
            <div className="columns mcolumns  grid divItem">
                <div className="columns20 footerIcons">
                   <MdOutlineQueryStats/>
                </div>
                 <div className="columns80">
                     <h6 className="smHeading">About us</h6>
                     <p className="footerSmP">
                        Fostering business growth through digital technologies. We are out to help you get a larger market chunk. Here's how
                     </p>
                </div>
            </div>
            </a>
            <a href="">
            <div className="columns mcolumns grid divItem">
                <div className="columns20 footerIcons">
                   <MdOutlinePhonelink />
                </div>
                 <div className="columns80">
                     <h6 className="smHeading">Websites that sell</h6>
                     <p className="footerSmP">
                        Customer need focused, creatively designed, lightening fast performance.
                     </p>
                </div>
            </div>
            </a>
            <a href="">
            <div className="columns mcolumns grid divItem">
                <div className="columns20 footerIcons">
                   <MdOutlineForum />
                </div>
                 <div className="columns80">
                     <h6 className="smHeading">Persuasive content</h6>
                     <p className="footerSmP">
                        The sales process begins when you connect with your customers. We identify needs and address them, persuasively.
                     </p>
                </div>
            </div>
            </a>
        </div>
        <div className="columns3 footerGridBox">
          <a href="">
            <div className="columns mcolumns  grid divItem">
                <div className="columns20 footerIcons">
                   <MdOutlineRadar />
                </div>
                 <div className="columns80">
                     <h6 className="smHeading">Ads that count</h6>
                     <p className="footerSmP">
                        Your marketing efforts go to waste when your ads and post get skipped. We cut down the waste with creative advertising.
                     </p>
                </div>
            </div>
            </a>
            <a href="">
            <div className="columns mcolumns  grid divItem">
                <div className="columns20 footerIcons">
                   <MdOutlineMarkAsUnread />
                </div>
                 <div className="columns80">
                     <h6 className="smHeading">CRM</h6>
                     <p className="footerSmP">
                        Make the most of your advertising dollars with returning customers.
                     </p>
                </div>
            </div>
            </a>
            <a href="#contact">
            <div className="columns mcolumns  grid divItem">
                <div className="columns20 footerIcons">
                   <MdOutlineCall />
                </div>
                 <div className="columns80">
                     <h6 className="smHeading">Ready for real results?</h6>
                     <p className="footerSmP">
                         Leave us a message right away. Take your business to the next level with innovative digital solutions. Get a larger market share with BITe.
                     </p>
                </div>
            </div>
            </a>
        </div>
    </div>
    <div className="spacer50"></div> 
  </div>
  </section>
  <div className="copyright center">©2024 BITe Agency | All Rights Reserved.</div>
  <div className="mspacer"></div><div className="mspacer"></div><div className="mspacer"></div><div className="mspacer"></div>
  </>
  );
  
return footer;
}

export default Footer;